<template>
    <div>
        <div class="card-toolbar mb-5">
            <router-link v-if="$can('inventory-transactions.create')" to="/items/inventory-transactions/create"  class="btn btn-primary font-weight-bolder"><v-icon>mdi-plus</v-icon> {{ $t('inventory_transactions.new_inventory_transaction') }}</router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="transaction_code">{{$t('inventory_transactions.transaction_code')}}</label>
                            <input v-model="filters.transaction_code" type="text" id="transaction_code" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="transaction_type">{{$t('inventory_transactions.transaction_type')}}</label>
                            <select name="transaction_type" id="transaction_type" v-model="filters.transaction_type" class="custom-select">
                                <option v-for="row in transaction_types" :value="row.id" :key="row.id">{{row.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="transaction_code">{{$t('inventory_transactions.inventory')}}</label>
                            <multiselect v-model="inventory"
                                :placeholder="$t('inventory_transactions.inventory')"
                                label="name"
                                track-by="id"
                                :options="inventories"
                                :multiple="false"
                                :taggable="true"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false">
                            </multiselect>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('inventory_transactions.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('inventory_transactions.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.is_active" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-10">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <div>
                    <div class="row justify-content-end p-4">
                        <custom-export-data v-if="$can('inventory-transactions.export_data')" :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.Inventory_transactions')"></custom-export-data>
                    </div>

                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <b-btn v-if="$can('inventory-transactions.change_status')" variant="secondary" @click="showModal(props.row.id, props.row.status)" :key="props.row.id">
                            {{$t('inventory_transactions.change_status')}}
                            ({{props.row.status_name}})
                        </b-btn>
                        <b-btn v-else variant="secondary" :key="props.row.id">
                            {{$t('inventory_transactions.change_status')}}
                            ({{props.row.status_name}})
                        </b-btn>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('inventory-transactions.update')" color="blue darken-2" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('inventory-transactions.delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>


                      <b-dropdown id="dropdown-offset" :text="$t('more_actions')"  variant="outline-primary" class="m-2">
                        <router-link v-if="$can('inventory-transactions.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/inventory-transactions/${props.row.id}/print`">
                          {{ $t('MENU.invoice_printing') }}
                        </router-link>
                        <router-link v-if="$can('inventory-transactions.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/inventory-transactions/${props.row.id}/pdf`">
                          {{ $t('export_pdf') }}
                        </router-link>
                        <router-link v-if="$can('inventory-transactions.invoice_printing_without_price')" target="_blank" class="dropdown-item" :to="`/print/inventory-transactions/${props.row.id}/print?is_show_price=0`">
                          {{ $t('MENU.invoice_printing_without_price') }}
                        </router-link>
                        <router-link v-if="$can('inventory-transactions.printing_export_pdf_without_price')" target="_blank" class="dropdown-item" :to="`/print/inventory-transactions/${props.row.id}/pdf?is_show_price=0`">
                          {{ $t('export_pdf_without_price') }}
                        </router-link>
                      </b-dropdown>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

<!--        <b-modal ref="modal" hide-footer :title="$t('inventory_transactions.change_status')">-->
<!--            <div class="col-lg-12 mb-5">-->
<!--                <label>{{$t('inventory_transactions.status')}}</label>-->
<!--                <select name="status" id="status" v-model="status_changed" class="custom-select">-->
<!--                    <option v-for="row in status_list" :value="row.id" :key="row.id">{{row.name}}</option>-->
<!--                </select>-->
<!--            </div>-->


<!--            <div class="d-flex justify-content-end">-->
<!--                <b-button class="mt-2 mr-1" variant="danger" @click="hideModal">{{$t('close')}}</b-button>-->
<!--                <b-button class="mt-2" variant="success" @click="changeStatus">{{$t('save')}}</b-button>-->
<!--            </div>-->
<!--        </b-modal>-->
        <b-modal ref="modal" size="md" hide-footer :title="$t('change_status')">
            <change-status-form @hide-modal="hideModal()"
                                :status-list="status_list"
                                :url="routeChangeStatus"
                                :current-id="id_changed"
                                :current-status="status_changed"
                                @handling-data="getDataAfterChangeStatus">
            </change-status-form>
        </b-modal>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

    export default {
        name: "index-inventory-transactions",
        components: {'change-status-form': ChangeStatusFrom,},
        data() {
            return {
                mainRoute: 'items/inventory-transactions',
                subMainRoute: 'items/inventory-transaction',
                routeChangeStatus: 'items/inventory-transaction/change-status',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    inventory: '',
                    transaction_code: '',
                    transaction_type: '',
                    from_date: '',
                    to_date: '',
                    status: '',
                },
                inventory: null,
                status_list: [
                    {id:1, title: this.$t('status_list.pending')},
                    {id:2, title: this.$t('status_list.confirm')}
                ],
                inventories: [],
                transaction_types: [
                    {id:1, name: this.$t('transaction_types.add_quantity')},
                    {id:2, name: this.$t('transaction_types.decrease_quantity')},
                    {id:3, name: this.$t('transaction_types.transfer_quantity')}
                ],

                columns: ['transaction_code', 'transaction_type_name', 'inventory_name', 'transaction_date', 'status', 'actions'],

                id_changed: null,
                status_changed: null,
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('inventory_transactions.inventory')] = 'inventory_name';
                fields[this.$t('inventory_transactions.status')] = 'status_name';
                fields[this.$t('inventory_transactions.transaction_code')] = 'transaction_code';
                fields[this.$t('inventory_transactions.date')] = 'transaction_date';
                fields[this.$t('inventory_transactions.transaction_reference')] = 'transaction_reference';
                fields[this.$t('inventory_transactions.transaction_reference')] = 'transaction_reference';
                fields[this.$t('inventory_transactions.transaction_type')] = 'transaction_type_name';
                fields[this.$t('inventory_transactions.notes')] = 'notes';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        transaction_code: that.$t('inventory_transactions.transaction_code'),
                        transaction_type_name: that.$t('inventory_transactions.transaction_type'),
                        inventory_name: that.$t('inventory_transactions.inventory'),
                        transaction_date: that.$t('inventory_transactions.date'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        watch:{
            inventory: function (val) {
                if (val){
                    this.filters.inventory = val.id;
                }else {
                    this.filters.inventory = null;
                }
            }
        },
        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.Inventory_transactions")}]);
            this.getInventories();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.inventory = '';
                this.filters.transaction_code = '';
                this.filters.transaction_type = '';
                this.filters.from_date = '';
                this.filters.to_date = '';
                this.filters.status = '';
                this.inventory = null;

                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(item) {
                this.$router.push({name: 'inventory-transactions.edit', params: {id: item.id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },
            getInventories() {
                ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
                    this.inventories = response.data.data;
                });
            },

            // changeStatus() {
            //     ApiService.patch(this.subMainRoute + '/change-status/' + this.id_changed, {
            //         status: this.status_changed,
            //     }).then(response => {
            //         this.$refs.table.refresh();
            //         this.$successAlert(response.data.message);
            //         this.hideModal();
            //     }).catch(error => {
            //         this.$errorAlert(error);
            //     });
            // },
            showModal(id,status) {
                this.$refs['modal'].show();
                this.id_changed = id;
                this.status_changed = status;
            },
            hideModal() {
                this.$refs['modal'].hide();
                this.id_changed = null;
                this.status_changed = null;
            },
            toggleModal() {
                this.$refs['modal'].toggle('#toggle-btn');
            },
            getDataAfterChangeStatus() {
                this.id_changed = null;
                this.status_changed = null;
                this.doFilter();
            },
        },
    };
</script>
